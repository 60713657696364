<template>
    <div :class="'content ' + content_class">
        {{ label }}
        <fade-transition :duration="100" mode="out-in">
            <!-- your content here -->

            <router-view></router-view>
        </fade-transition>
    </div>
</template>
<script>
import Breadcrumb from "primevue/breadcrumb";
import { FadeTransition } from "vue2-transitions";

export default {
    props: ['content_class'],
    data() {
        return {
            home: { icon: "pi pi-home", to: "/" },
            items: null,
        };
    },

    created() {},

    computed: {
        label() {
            try {
                var path = this.$route.path;
                var paths = path.split("/");

                if (paths.length == 2) {
                    var item = this.$router.history.current.name;
                    console.log(item);
                    this.items = [{ label: item }];
                } else {
                    var obj = [];
                    var pathsVerdad = paths.map((item) => {
                        if (!item == "") {
                            obj.push({ label: item });
                        }
                    });
                    this.items = obj;
                }
            } catch (error) {}
        },
    },

    components: {
        FadeTransition,
        Breadcrumb,
    },
};
</script>
<style>
.p-breadcrumb {
    padding: 0px !important;
    border: none !important;
    background: transparent !important;
}

.content {
  padding-top: 40px !important;
  transition: all 0.9s;
}

.menu_open_content {
  padding-left: 220px !important;
}

.menu_close_content {
  padding-left: 30px !important;
}

@media (max-width: 991px) {
    .content {
        padding-left: 30px !important;
    }
}
</style>
