<template>
    <div class="nav-item button-session-destroy" v-on:click="sessionDestroy">
        <a class="nav-link">
            <slot>
                <i v-if="icon" :class="icon + ' font-size-14'"></i>
                <div class="text-and-badge">
                    <div>
                        <p class="no-select">{{ name }}</p>
                    </div>
                    <div class="badge-content" v-if="notification > 0">
                        <Badge
                            :value="notification"
                            class="badge-text p-mr-2 notification"
                            size="small"
                        ></Badge>
                    </div>
                </div>
            </slot>
        </a>
    </div>
</template>
<script>
import Badge from "primevue/badge";

import "primeflex/primeflex.css";

export default {
    name: "sidebar-link",
    inheritAttrs: false,
    inject: {
        autoClose: {
            default: true,
        },
        addLink: {
            default: () => {},
        },
        removeLink: {
            default: () => {},
        },
    },
    props: {
        name: String,
        icon: String,
        notification: Number,
        tag: {
            type: String,
            default: "router-link",
        },
    },
    components: {
        Badge,
    },
    methods: {
        sessionDestroy() {
            this.$store.commit("saveToken", null);
            this.$store.commit("saveUserId", null);
            this.$store.commit("saveUser", null);

            //Removendo o HTTP_TOKEN do HEADER
            console.log(this.$http.defaults.headers.common);
            var accept = this.$http.defaults.headers.common.Accept;
            this.$http.defaults.headers.common = {};
            this.$http.defaults.headers.common.Accept = accept;
            console.log(this.$http.defaults.headers.common);

            window.localStorage.clear();
            this.$router.push({ path: "/login" });
        },
    },
};
</script>
<style scoped>
</style>
