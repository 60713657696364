<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },

    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    },


  },
  mounted() {

    this.$root.resultValidationRequest = (result) => {
      const internalError = 'Error interno !';
      const notDatos = "No se encontraron datos, por favor espere."

      if(!result.status) {
        result = result.response;
      }
  
      if (result.status != 200) {

        if(result.data.errors){
          const error = Object.values(result.data.errors)[0][0];
        
          return error;
        }

        if (result.data.message) {
          
          return result.data.message;
        }

        if (result.data.error) {

          return result.data.error;
        }

        return internalError;
      }

      if (!result.data) {
        return internalError;
      }

      if (result.data == 1) {
        return internalError;
      }

      if(result.data.total == 0) {
        return notDatos;
      }

      return true;
    }

    this.$watch('$route', this.disableRTL, { immediate: true });
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen);
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: 0.2rem;
}
</style>
