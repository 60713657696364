<template>
  <div :class="containerClass+' customPanelMenu'">
    <div class="p-panel-header" @click="toggle">
      <slot name="header">
        <div>
          <i v-if="icon" :class="icon +' font-size-14'" class="pnael-icon"></i>
          <span class="p-panel-title"  v-if="header" :id="ariaId + '_header'">{{header}}</span>
        </div>
      </slot>
      <div class="p-panel-icons">
        <slot name="icons"></slot>
          <span :class="{ 'pi pi-caret-down': !d_collapsed, 'pi pi-caret-right': d_collapsed } + ' font-size-10'"></span>
      </div>
    </div>
    <transition name="p-toggleable-content">
      <div
        class="p-toggleable-content"
        v-show="!d_collapsed"
        role="region"
        :id="ariaId + '_content'"
        :aria-labelledby="ariaId + '_header'"
      >
        <div class="p-panel-content">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UniqueComponentId from "primevue/utils/UniqueComponentId";
import Ripple from "primevue/ripple";

export default {
  props: {
    header: String,
    toggleable: Boolean,
    collapsed: Boolean,
    icon: String,
  },
  data() {
    return {
      d_collapsed: this.collapsed,
    };
  },
  watch: {
    collapsed(newValue) {
      this.d_collapsed = newValue;
    },
  },
  computed: {
    ariaId() {
      return UniqueComponentId();
    },
    containerClass() {
      return ["p-panel p-component", { "p-panel-toggleable": this.toggleable }];
    },
  },
  methods: {
    toggle(event) {
      this.d_collapsed = !this.d_collapsed;
      this.$emit("update:collapsed", this.d_collapsed);
      this.$emit("toggle", {
        originalEvent: event,
        value: this.d_collapsed,
      });
    },
  },
  directives: {
    ripple: Ripple,
  },
};
</script>

<style>

</style>
