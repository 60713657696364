const alert = {
    state: {
        name: 'alerts',
        errors: [],
        quant: 0, 
    },
    getters: {},
    mutations: {
        addErrors(state, error) {
            this.errors.push(error);
        },

        addQuant(state, quant) {
            this.quant += quant;
            console.log(this.quant);
        },

        setQuant(state, quant) {
            this.quant = quant;
            console.log(this.quant);
        }
    },
    actions: {
        alert_error(context, {
            message,
            time
        }) {
            this._vm.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger',
                timeout: time ? time : 0
            });
        },
        alert_error_not_icon(context, {
            message,
            time
        }) {
            this._vm.$notify({
                message: message,
                icon: "",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger',
                timeout: time ? time : 0
            });
        },
        alert_success(context, {
            message,
            time
        }) {
            this._vm.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success',
                class: 'alert-success-custom',
                timeout: time ? time : 0
            });
        },

        alert_carge(context, time) {
            this._vm.$notify({
                message: this.quant + ' - 2000',
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success',
                ref_alter: 'ref_alter',
                overlap: true,
                class: 'alert-success-custom',
                timeout: time ? time : 0
            });
        },

        alert_warning(context, {
            message,
            time
        }) {
            this._vm.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'bottom',
                type: 'success',
                class: 'alert-warning-custom',
                timeout: time ? time : 0
            });
        },

        alert_success_bottom(context, {
            message,
            time
        }) {
            this._vm.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: 'right',
                verticalAlign: 'bottom',
                type: 'success',
                class: 'alert-success-custom',
                timeout: time ? time : 0
            });
        }
    }
}
export default alert;